import { SearchBar as DSSearchBar } from '@cash-me/react-components'
import { fontWeights } from '@cash-me/web-tokens'
import styled from 'styled-components'

import { theme } from 'styles/theme'

const { colors, media, space, borderWidths, fontSizes, lineHeights } = theme

export const Section = styled.section`
  width: 100%;

  @media (${media.desktop}) {
    padding-top: 7.5rem;
  }
`

export const Title = styled.h1`
  margin-bottom: ${space['spacing-md']};
  color: ${colors['neutral-text-default']};
  font-size: ${fontSizes['font-size-mobile-heading-2']};
  font-weight: ${fontWeights['font-weight-medium']};

  @media (${media.desktop}) {
    margin-bottom: ${space['spacing-xl']};
    font-size: ${fontSizes['font-size-heading-1']};
    text-align: center;
  }
`

export const SearchBar = styled(DSSearchBar)`
  margin-bottom: ${space['spacing-xxxl']};

  @media (${media.desktop}) {
    margin-bottom: 7.5rem;
  }
`

export const Subtitle = styled.h2`
  margin-bottom: ${space['spacing-md']};
  color: ${colors['neutral-text-default']};
  font-size: ${fontSizes['font-size-mobile-heading-3']};
  font-weight: ${fontWeights['font-weight-medium']};

  @media (${media.desktop}) {
    margin-bottom: ${space['spacing-xl']};
    font-size: ${fontSizes['font-size-heading-3']};
  }
`

export const AccordionWrapper = styled.div`
  margin-bottom: ${space['spacing-mega']};

  .ds-accordion-item {
    margin-bottom: 0;
    border-bottom: ${borderWidths['border-stroke-micro']} solid ${colors['neutral-border-default']};

    > summary {
      padding: ${space['spacing-sm']} 0;

      h3 {
        font-size: ${fontSizes['font-size-body-1']};
      }
    }

    > * {
      padding-bottom: ${space['spacing-sm']};
    }

    > *:last-child {
      padding-bottom: ${space['spacing-md']};
    }

    a {
      cursor: pointer;
    }
  }

  @media (${media.desktop}) {
    margin-bottom: 6rem;

    .ds-accordion-item {
      > summary {
        padding: ${space['spacing-md']} 0;

        h3 {
          font-size: ${fontSizes['font-size-heading-5']};
        }
      }
    }
  }
`

export const WrapBottom = styled.div`
  padding: ${space['spacing-xxl']} 0;
  background-color: ${colors['neutral-background-medium']};

  @media (${media.tablet}) {
    background-color: transparent;
    padding-top: 0;
  }
`

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space['spacing-md']};

  .ds-button {
    width: 100%;
  }

  @media (${media.tablet}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: ${fontSizes['font-size-heading-5']};
    }

    .ds-button {
      width: auto;
      height: ${space['spacing-xxl']};
      min-height: ${space['spacing-xxl']};
    }
  }
`

export const List = styled.ul`
  padding-bottom: 0 !important;
`

export const ListItem = styled.li`
  margin-bottom: ${space['spacing-md']};
  font-size: ${fontSizes['font-size-body-2']};
  color: ${colors['neutral-text-weak']};
  line-height: ${lineHeights['font-line-height-lg']};

  &::before {
    content: '●';
    display: inline-block;
    vertical-align: text-bottom;
    padding-right: ${space['spacing-xxs']};
    font-size: ${fontSizes['font-size-caption']};
    color: ${colors['neutral-text-weak']};
  }
`
