import { FC, memo, useCallback, useEffect, useState } from 'react'

import { AccordionNoRadix, Button, Divider, Icon, Text } from '@cash-me/react-components'
import NextLink from 'next/link'
import { useRouter } from 'next/router'

import { Grid, Col } from 'components/grid'
import { pushDataLayer } from 'utils/push-data-layer'
import { questionList } from 'views/faq/questions/data'
import { AccordionProps as questionModel } from 'views/faq/questions/questions.model'

import * as S from './styles'

export const QuickHelp: FC = memo(() => {
  const router = useRouter()
  const [term, setTerm] = useState<string>('')
  const [visibleItems, setVisibleItems] = useState<questionModel[]>([])

  useEffect(() => {
    setVisibleItems([])
    const filteredItems: questionModel[] = []

    questionList.map(({ questions }) => {
      if (filteredItems.length > 1) return

      questions.map(item => {
        if (filteredItems.length > 1) return

        if (
          item.title.toLowerCase().includes(term.toLowerCase()) ||
          item.content.toString().toLowerCase().includes(term.toLowerCase())
        )
          filteredItems.push(item)
      })
    })

    setVisibleItems(filteredItems)
  }, [term])

  const handleDataLayer = useCallback(() => {
    pushDataLayer({
      category: 'Clique',
      action: 'Ajuda',
      label: 'Botão Veja mais Dúvidas'
    })

    window.location.href = '/fale-conosco/#contact-form'
  }, [])

  return (
    <Grid col={{ desktop: 1 }}>
      <Col>
        <S.QuickHelpWrapper>
          {router.pathname !== '/ajuda' && (
            <S.QuickHelp>
              <AccordionNoRadix
                id="quickhelp"
                title={
                  <Text
                    size="body2"
                    weight="medium"
                    color="var(--colors-neutral-text-default)"
                    css={{ display: 'flex', gap: 'var(--space-spacing-xs)' }}
                  >
                    <Icon name="helpCircle" color="var(--colors-blue-icon-default)" size={20} /> Ajuda
                  </Text>
                }
              >
                <S.List>
                  <S.SearchBar id="filter" name="filter" placeholder="Qual é sua dúvida?" onChange={setTerm} />

                  {visibleItems.map(({ id, title }) => (
                    <NextLink key={id} href={`/ajuda?question=${id}`}>
                      <S.ListItem>
                        <Text size="body2" color="var(--colors-neutral-text-weak)">
                          {title}
                        </Text>
                        <Icon name="chevronRight" size={24} />
                      </S.ListItem>

                      <Divider />
                    </NextLink>
                  ))}

                  <NextLink href={`/ajuda${term ? `?search=${term}` : ''}`} className="ds-link">
                    Ver todas as respostas
                    <Icon name="arrowRight" size={12} />
                  </NextLink>
                </S.List>

                <S.ContactUs>
                  <Text weight="medium" color="var(--colors-blue-text-strong)">
                    Não encontrou o que procurava?
                  </Text>
                  <Button color="primary" size="md" iconName="arrowRight" onClick={handleDataLayer}>
                    Fale com a gente
                  </Button>
                </S.ContactUs>
              </AccordionNoRadix>
            </S.QuickHelp>
          )}
        </S.QuickHelpWrapper>
      </Col>
    </Grid>
  )
})

QuickHelp.displayName = 'QuickHelp'
