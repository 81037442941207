import { useCallback } from 'react'

const useLocalStorage = () => {
  const setItem = useCallback((key, value) => {
    if (global.localStorage) global.localStorage.setItem(key, value)
  }, [])

  const getItem = useCallback(key => {
    if (!global.localStorage) return

    const value: string = global.localStorage.getItem(key) || ''
    return value || null
  }, [])

  return {
    setItem,
    getItem
  }
}
export default useLocalStorage
