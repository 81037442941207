import styled from 'styled-components'

import { theme } from 'styles/theme'

const { colors, space, media } = theme

export const WarningWrap = styled.div`
  width: 100%;
  background-color: ${colors['neutral-background-contrast']};
`

export const Warning = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${space['spacing-xxxl']};
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: ${space['spacing-sm']};

  @media (${media.phone}) {
    .ds-icon {
      display: none;
    }
  }
`

export const Close = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${space['spacing-xl']};
  cursor: pointer;
`
