import { FC, memo, useCallback, useState } from 'react'

import { Text, Icon } from '@cash-me/react-components'

import { Grid, Col } from 'components/grid'
import useLocalStorage from 'hooks/useLocalStorage'

import * as S from './styles'

const Warning: FC = memo(() => {
  const { setItem } = useLocalStorage()

  const [showWarning, setShowWarning] = useState(true)

  const handleCloseWarning = useCallback(() => {
    setItem('cashme-warning', new Date())
    setShowWarning(false)
  }, [setItem])

  if (!showWarning) return <></>

  return (
    <S.WarningWrap>
      <Grid col={{ phone: 1, tablet: 1 }}>
        <Col col={{ desktop: 10 }} start={{ desktop: 2 }}>
          <S.Warning>
            <S.Content>
              <Icon name="attentionCircle" size={24} color="var(--colors-blue-icon-strong)" />
              <Text size="caption" weight="medium" color="var(--colors-neutral-text-contrast-default)">
                A CashMe não solicita depósito ou pagamento antecipado para aprovação de crédito.
              </Text>
            </S.Content>
            <S.Close aria-label="Fechar aviso" onClick={handleCloseWarning}>
              <Icon name="close" size={24} color="var(--colors-blue-icon-strong)" />
            </S.Close>
          </S.Warning>
        </Col>
      </Grid>
    </S.WarningWrap>
  )
})

Warning.displayName = 'Warning'

export default Warning
